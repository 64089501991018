import {createSlice} from "@reduxjs/toolkit";

const titleImageFileSlice = createSlice({
    name: "titleImageFile",
    initialState: null,
    reducers: {
        setImageFile(state, action) {
            const regexp = new RegExp("^data:image\/[a-z]+;base64,");
            let result = action.payload;
            if(!regexp.test(action.payload) && action.payload){
                result = "data:image/*;base64," + result;
            }
            return result;
        }
    }
})

export const {setImageFile} = titleImageFileSlice.actions;

export default titleImageFileSlice.reducer;