import { useRef } from "react";
import SetterTags from "../SetterTags/SetterTags";

import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import style from "./WrapperForSetterTags.module.css";
import { useDispatch } from "react-redux";
import { deleteTableWithElementsById } from "../../store/selectedQueryElementsSlice";

export default function WrapperForSetterTags({index, query_data}) {
    const ref = useRef(null);
    const dispatch = useDispatch();

    function handleRemoveClick() {
        dispatch(deleteTableWithElementsById(index))
    }

    const button =
        <IconButton className={style.button} onClick={handleRemoveClick}>
            <CloseIcon />
        </IconButton>;

    const ghostButton =
        <IconButton className={style.ghostButton}>
            <CloseIcon />
        </IconButton>;
    
    return(
        <div className={style.wrapper} ref={ref}>
            <SetterTags query_data={query_data} index={index}/>
            {parseInt(index) !== 0 ? button : ghostButton}
        </div>
    )
}