import ExampleTheme from "./themes/themes";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";

import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { InlineImageNode } from "./nodes/InlineImageNode";
import { ImageNode } from "./nodes/ImageNode";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";
import { $generateHtmlFromNodes } from '@lexical/html';

import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
//import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
//import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin'
import InlineImagePlugin from "./plugins/InlineImagePlugin";
import OnChangePlugin from "./plugins/OnChangePlugin";
import ImagesPlugin from "./plugins/ImagePlagin";

import { setHTML } from "../../store/htmlFromCurrentEditorStateSlice";
import { setEditorState } from "../../store/EditorStateSlice";

import styles from './Editor.module.css'

function Placeholder() {
  return <div className="editor-placeholder">Введите текст...</div>;
}

const editorConfig = {
  // The editor theme
  theme: ExampleTheme,
  // Handling of errors during update
  onError(error) {
    throw error;
  },
  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode,
    InlineImageNode,
    ImageNode,
  ],
  
};

function handleChange (editor, dispatch) {
  editor.update(() => {
    const editorState = editor.getEditorState();
    if(editorState._nodeMap.size < 3){ //Это условие проверяет отсутствие содержимого в редакторе
      dispatch(setHTML(null));
      return;
    }

    const htmlString = $generateHtmlFromNodes(editor, null);
    dispatch(setHTML(htmlString));
    dispatch(setEditorState(JSON.stringify(editorState)));
    });
};

export default function Editor({editorState}) {

  if(editorState){
    editorConfig.editorState = editorState;
  }
  else{
    editorState = JSON.stringify({"root":{"children":[{"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":"","type":"text","version":1}],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}});
    editorConfig.editorState = editorState;
  }

  return (
    <LexicalComposer initialConfig={editorConfig} className={styles.lexicalComposer}>
      <div className={styles.editorContainer}>
        <ToolbarPlugin />
        <div className="editor-inner">
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" />}
            placeholder={<Placeholder />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <HistoryPlugin />
          <AutoFocusPlugin />
          
          <ListPlugin />
          <LinkPlugin />
          <AutoLinkPlugin />
          <ListMaxIndentLevelPlugin maxDepth={7} />
          <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
          <InlineImagePlugin />
          <ImagesPlugin />

          <OnChangePlugin onChange={handleChange} />
        </div>
      </div>
    </LexicalComposer>
  );
}





/*import {LexicalComposer} from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';

import styles from './Editor.module.css';

function onError(error) {
    console.error(error);
}

const theme = {};
  
export default function Editor() {
    const initialConfig = {
      namespace: 'MyEditor',
      theme,
      onError,
    };
  
    return (
      <LexicalComposer initialConfig={initialConfig}>
        <RichTextPlugin
          contentEditable={<ContentEditable className={styles.ContentEditable}/>}
          placeholder={<div className={styles.placeholder}>Enter some text...</div>}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <HistoryPlugin />
        
      </LexicalComposer>
    );
}*/