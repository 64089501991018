import React, { useRef, useEffect } from 'react';
import { useLocation, useMatch } from 'react-router-dom';

import { useGetQueryDataQuery } from '../../store/apiSlice';
import { useDispatch } from "react-redux";
import { setTitle } from "../../store/titleSlice";
import { setImageFile } from '../../store/titleImageFileSlice.js';
import { setEditorState } from '../../store/EditorStateSlice.js';
import { setHTML } from '../../store/htmlFromCurrentEditorStateSlice.js';
import { setSelectedQueryElements } from '../../store/selectedQueryElementsSlice.js';

import TitleTextField from '../TitleTextField/TitleTextField.js';
import Editor from "../Editor/Editor.js";
import ImageLoader from '../ImageLoader/ImageLoader.js';
import SetterTags from '../SetterTags/SetterTags.js';
import SubmitButton from '../SubmitButton/SubmitButton.js';
import Loader from '../Loader/Loader.js';

import styles from './CreateArticlePage.module.css';
import ListOfTagSetters from "../ListOfTagSetters/ListOfTagSetters";

export default function CreateArticlePage({data, image, query_data}) {
    //const imageRef = useRef(null);
    // const {
    //   data: query_data,
    //   isSuccess,
    //   isLoading
    // } = useGetQueryDataQuery();
    const dispatch = useDispatch();

    useEffect(() => {
      if(data !== null){
        dispatch(setTitle(data.title));
        
        const strImage = image.replace(/^data:image\/[a-z*]+;base64,/, "");
        //console.log(strImage);
        dispatch(setImageFile(strImage));
        dispatch(setEditorState(data.editorState));
        dispatch(setHTML(data.html));

        //todo: связано с переходом на новый формат хранения тегов. Потом можно будет удалить проверку
        let finalTags;
        if(data.tags[0]){
          finalTags = data.tags;
        }
        else {
            finalTags =  [];
            const tags = structuredClone(data.tags)
            Object.keys(tags).forEach((key) => {
                tags[key] = tags[key].filter((el) => el !== "Не знаю");
            })
            const tagValue = tags["With_what_kind_of_energy"]
            console.log(tagValue)
            delete tags["With_what_kind_of_energy"];
            tags.Using = [];
            query_data.forEach((elQD) => {
                tags[elQD.hintTextEn] = tags[elQD.hintTextEn].filter((elTags) => elQD.queryElements.includes(elTags));
            })


            console.log(tags)

            finalTags.push(tags);
        }
        dispatch(setSelectedQueryElements(finalTags));

      }
    }, []);
    
    

    // const reader = new FileReader();
    // reader.addEventListener(
    //     "load",
    //     () => {
    //       imageRef.current.src = reader.result;
    //     },
    //     false,
    // );
    
    const contentSetterTags = <ListOfTagSetters query_data={query_data}/>;
    // let contentSetterTags = <></>;
    //
    // if(isSuccess){
    //   contentSetterTags = <ListOfTagSetters query_data={query_data}/>;
    // }
    // else if(isLoading){
    //   contentSetterTags = <Loader />;
    // }

    return (
        <div className={styles.wrapper}>
            <TitleTextField title={data?.title}/>
            <Editor editorState={data?.editorState}/>
            <ImageLoader imageFile={image}/>
            {contentSetterTags}
            <SubmitButton />
        </div>
    );
}