import {configureStore} from "@reduxjs/toolkit";

import htmlFromCurrentEditorStateReducer from "./htmlFromCurrentEditorStateSlice";
import titleImageFileReducer from "./titleImageFileSlice";
import selectedQueryElementsReducer from "./selectedQueryElementsSlice";
import titleSliceReducer from "./titleSlice";
import editorStateReducer from "./EditorStateSlice";
import { apiSlice } from "./apiSlice";

export default configureStore({
    reducer: {
        htmlFromCurrentEditorState: htmlFromCurrentEditorStateReducer,
        titleImageFile: titleImageFileReducer,
        selectedQueryElements: selectedQueryElementsReducer,
        title: titleSliceReducer,
        editorState: editorStateReducer,
        [apiSlice.reducerPath]: apiSlice.reducer, 
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
})