import styles from './Input.module.css'

export default function TextInput({
  label,
  value,
  onChange,
  placeholder = '',
  'data-test-id': dataTestId,
  type = 'text',
}) {
  return (
    <div className={styles.Input__wrapper}>
      <label className={styles.Input__label}>{label}</label>
      <input
        type={type}
        className={styles.Input__input}
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          onChange(e.target.value)
        }}
        data-test-id={dataTestId}
      />
    </div>
  )
}