import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({baseUrl: 'https://api.aptaran.ru'}),
    tagTypes: ["Articles"],
    endpoints: builder => ({
        getArticlesByTitle: builder.query({
            query: ({requset, page}) => `/articlesByTitle?q=${requset}&p=${page}`,
            providesTags: ["Articles"],
        }),
        getCountByTitle: builder.query({
            query: (requset) => `/getCountByTitle/index.php?q=${requset}`,
            providesTags: ["Articles"],
        }),
        getValuesForEditorPage: builder.query({
            query: id => `/getValuesForEditPage/index.php?id=${id}`,
            providesTags: ["Articles"],
        }),
        getQueryData: builder.query({
            query: () => '/queryData'
        }),
        addNewArticle: builder.mutation({
            query: (newPost) => ({
                url: `/addArticle/index.php`,
                method: 'POST',
                body: newPost
            }),
            invalidatesTags: ["Articles"],
        }),
        updateArticle: builder.mutation({
            query: (newPost) => ({
                url: `/updateArticle/index.php`,
                method: 'POST',
                body: newPost
            }),
            invalidatesTags: ["Articles"],
        }),
        deleteArticle: builder.mutation({
            query: (id) => ({
                url: `/deleteArticle/index.php`,
                method: 'POST',
                body: id
            }),
            invalidatesTags: ["Articles"],
        }),
        getImageForArticle: builder.query({
            query: ({id, name}) => ({
                url: `/getImage/index.php?id=${id}&name=${name}`,

                responseHandler: (response) => response.blob().then(blob => {
                    const reader = new FileReader();
                    return new Promise((resolve) => {
                        reader.onload = () => {
                            resolve(reader.result);
                        };
                        reader.readAsDataURL(blob);
                    })
                })
            }),
            providesTags: ["Articles"],
        }),

    })
})

export const {
    useGetArticlesByTitleQuery,
    useGetCountByTitleQuery,
    useGetValuesForEditorPageQuery,
    useGetQueryDataQuery,
    useAddNewArticleMutation,
    useUpdateArticleMutation,
    useDeleteArticleMutation,
    useGetImageForArticleQuery,
} = apiSlice;