import styles from './Button.module.css';

import * as React from 'react';

export default function Button({
  'data-test-id': dataTestId,
  children,
  className,
  onClick,
  disabled,
  small,
  title,
}) {
  return (
    <button
      disabled={disabled}
      className={`${styles.Button__root} ${disabled && styles.Button__disabled} ${small && styles.Button__small} ${className}`}
      onClick={onClick}
      title={title}
      aria-label={title}
      {...(dataTestId && {'data-test-id': dataTestId})}>
      {children}
    </button>
  );
}