import styles from './TableForSetterTags.module.css';

export default function TableForSetterTags({elements_for_table, hint_text}) {

    let processed_hint_text = hint_text && hint_text.replaceAll('_', ' ') + '?';

    return (
        <div className={styles.main_block}>
            <div className={styles.wrapper_hint_text}>
                <span className={styles.hint_text}>{processed_hint_text}</span>
            </div>
            <div className={styles.block_with_req_el}>{elements_for_table}</div>
        </div>
    );
}