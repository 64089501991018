import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { useState, useRef, useEffect } from 'react';

import { useDispatch } from "react-redux";
import { setImageFile } from "../../store/titleImageFileSlice";

import ReactCrop, {
    centerCrop,
    makeAspectCrop,
} from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from './useDebounceEffect';

function centerAspectCrop(
    mediaWidth,
    mediaHeight,
    aspect,
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 100,
        },
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    )
  }

export default function ImageCrop({src}){
  const [open, setOpen] = useState(true);
  const [crop, setCrop] = useState();
  const previewCanvasRef = useRef(null)
  const imgRef = useRef(null)
  const [completedCrop, setCompletedCrop] = useState();
  const aspect = 1;
  const dispatch = useDispatch();

  const reader = new FileReader();
  reader.addEventListener('load', () => {
    dispatch(setImageFile(reader.result))},
  );

  useEffect(() => {
    setOpen(true)
  }, [src]);

  const onImageLoad = (e) => {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  const handleOK = async() => {
    const image = imgRef.current
    const previewCanvas = previewCanvasRef.current
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error('Crop canvas does not exist')
    }

    const offscreen = new OffscreenCanvas(
      completedCrop.width,
      completedCrop.height,
    )
    const ctx = offscreen.getContext('2d')
    if (!ctx) {
      throw new Error('No 2d context')
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height,
    )
    // You might want { type: "image/jpeg", quality: <0 to 1> } to
    // reduce image size
    const blob = await offscreen.convertToBlob({
      type: 'image/png',
    });

    reader.readAsDataURL(blob);

    setOpen(false);
  }

    const handleClose = () => {
        setOpen(false);
    }

    useDebounceEffect(
      async () => {
        if (
          completedCrop?.width &&
          completedCrop?.height &&
          imgRef.current &&
          previewCanvasRef.current
        ) {
          // We use canvasPreview as it's much faster than imgPreview.
          canvasPreview(
            imgRef.current,
            previewCanvasRef.current,
            completedCrop,
            1,
            0,
          )
        }
      },
      100,
      [completedCrop],
    )

    return(
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <ReactCrop 
                crop={crop} 
                onChange={(_,c) => setCrop(c)}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={aspect}
                minWidth={40}
                minHeight={50}
                style={{margin:"10px"}}
                >
                <img src={src} ref={imgRef} onLoad={onImageLoad}/>
            </ReactCrop>
            {completedCrop &&
              <div>
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    border: '1px solid black',
                    objectFit: 'contain',
                    width: completedCrop.width,
                    height: completedCrop.height,
                    display: 'none'
                  }}
                />
              </div>
            }
            <DialogActions>
                <Button onClick={handleClose}>Отмена</Button>
                <Button onClick={handleOK} autoFocus>Ок</Button>
            </DialogActions>
        </Dialog>
    );
}