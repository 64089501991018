import stylesSelect from './Select.module.css';
import stylesInput from './Input.module.css';

export default function Select({
  children,
  label,
  className,
  ...other
}) {
  return (
    <div className={stylesInput.Input__wrapper}>
      <label style={{marginTop: '-1em'}} className={stylesInput.Input__label}>{label}</label>
      <select {...other} className={className || stylesSelect.select}>{children}</select>
    </div>
  )
}