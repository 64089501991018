import { useState, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDeleteArticleMutation } from '../../store/apiSlice';

import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText,
         DialogTitle, Slide } from "@mui/material";

import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import TitleIcon from '@mui/icons-material/Title';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ArticleElements({ID, title}) {
    const [inConfirmationMode, setInConfirmationMode] = useState(false);
    const [deleteArticle, {isLoading}] = useDeleteArticleMutation();
    const navigate = useNavigate();

    return (
        <>
            <Paper
                sx={{ p: '10px 4px', display: 'flex', alignItems: 'center', width: 600 }}
            >
                <Grid3x3Icon /><span style={{marginBottom: '4px', marginRight: '1rem'}}>{ID}</span>
                <TitleIcon /> <span style={{marginBottom: '4px'}}>{title}</span>
                <IconButton disabled={isLoading} sx={{marginLeft: 'auto'}} onClick={() => setInConfirmationMode(true)}>
                    <DeleteIcon sx={{color: '#E91E63'}}/>
                </IconButton>
                <IconButton>
                    <EditIcon color='primary' onClick={() => navigate(`/edit-article/${ID}`)}/>
                </IconButton>
            </Paper>

            <Dialog
                open={inConfirmationMode}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setInConfirmationMode(false)}>
                    <DialogTitle>{"Подтверждение"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Вы действительно хотите удалить элемент?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setInConfirmationMode(false)}>Отменить</Button>
                        <Button onClick={() => {
                            deleteArticle(ID).unwrap();
                            setInConfirmationMode(false);
                        }}>ОК</Button>
                    </DialogActions>
            </Dialog>
        </>
    );
}