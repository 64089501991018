import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

export default function OnChangePlugin({ onChange }) {
    const dispatch = useDispatch();
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        return editor.registerUpdateListener(() => {
            onChange(editor, dispatch);
        });
        
    }, [editor, onChange]);
  }

  