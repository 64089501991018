import { useState, forwardRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useMatch } from "react-router-dom";
import { useAddNewArticleMutation } from "../../store/apiSlice";
import { useUpdateArticleMutation } from "../../store/apiSlice";

import { Button, Dialog, DialogActions, DialogContent, DialogContentText,
         DialogTitle, Slide, Alert, AlertTitle } from "@mui/material";
import RegexEscape from "regex-escape";

import styles from './SubmitButton.module.css';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export default function SubmitButton() {
    const [inConfirmationMode, setInConfirmationMode] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);

    let html = useSelector(state => state.htmlFromCurrentEditorState);
    const editorState = useSelector(state => state.editorState);

    let selectedQueryElements = useSelector (state => state.selectedQueryElements);
    selectedQueryElements = Object.values(selectedQueryElements)

    let titleImageFile = useSelector(state => state.titleImageFile);
    titleImageFile = titleImageFile?.replace(/^data:image\/.+;base64,/, "");

    const title = useSelector(state => state.title);

    const match = useMatch('/edit-article/:ID')
    const id = match?.params.ID;
    let listOfContents;

    const [addNewArticle, { isLoading: isLoadingNewArticle, isError: isErrorNewArticle, isSuccess: isSuccessNewArticle}] = useAddNewArticleMutation();
    const [updateArticle, { isLoading: isLoadingUpdateArticle, isError: isErrorUpdateArticle, isSuccess: isSuccessUpdateArticle}] = useUpdateArticleMutation();

    useEffect(() => {
        if((isErrorNewArticle || isErrorUpdateArticle)){
            setShowErrorAlert(true);
            setTimeout(() => setShowErrorAlert(false), 3000);
        }
    }, [isErrorNewArticle, isErrorUpdateArticle]);

    useEffect(() => {
        if((isSuccessNewArticle || isSuccessUpdateArticle)){
            setShowSuccessAlert(true);
            setTimeout(() => setShowSuccessAlert(false), 3000);
        }
    }, [isSuccessNewArticle, isSuccessUpdateArticle]);

    const canSave = [title, html, editorState, selectedQueryElements, titleImageFile].every(Boolean) && !isLoadingNewArticle && !isLoadingUpdateArticle;

    if(canSave) {
        
    }

    const handleClickOnOKButton = () => {
        try {
            listOfContents = getListOfContents(html, title);
            const h1 = `<h1><span>${title}</span></h1>`;
            html = h1 + html;
            html = getHTMLwithId(html, listOfContents);

            id ? updateArticle({title, html, editorState, selectedQueryElements, titleImageFile, id, listOfContents}).unwrap() : 
            addNewArticle({title, html, editorState, selectedQueryElements, titleImageFile, listOfContents}).unwrap();
            setInConfirmationMode(false);
        }
        catch (error){
            setShowErrorAlert(true);
            console.log(error);
            setTimeout(() => setShowErrorAlert(false), 3000);
        }
        
    }

    return (
        <div className={styles.Wrapper}>
            <Button disabled={!canSave} variant="contained" onClick={() => setInConfirmationMode(true)}>Отправить</Button>

            <Dialog
            open={inConfirmationMode}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setInConfirmationMode(false)}>
                <DialogTitle>{"Подтверждение"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Вы действительно хотите {id ? 'обновить' : 'создать'} элемент?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setInConfirmationMode(false)}>Отменить</Button>
                    <Button disabled={!canSave} onClick={handleClickOnOKButton}>ОК</Button>
                </DialogActions>
            </Dialog>
            <div className={styles.Wrapper_for_alert}>
                <Slide direction="down" in={showErrorAlert} mountOnEnter unmountOnExit
                    sx={{mx: "auto"}}
                >
                    <Alert severity="error" variant="filled" sx={{mx: "auto"}}>
                        <AlertTitle>Ошибка! Не удалось {id ? 'обновить' : 'создать'} элемент.</AlertTitle>
                    </Alert>
                </Slide>

                <Slide direction="down" in={showSuccessAlert} mountOnEnter unmountOnExit
                    sx={{mx: "auto"}}
                >
                    <Alert severity="success" variant="filled" sx={{mx: "auto"}}>
                        <AlertTitle>Успех! Элемент {id ? 'обновлён' : 'добавлен'}.</AlertTitle>
                    </Alert>
                </Slide>
            </div>
            
        </div> 
    );
}

//Функция для создания оглавления
function getListOfContents(html, title){ 
    let list_of_content = [];
    list_of_content.push( {
        title: title,
        subtitles: null
    });

    const regexpH2 = new RegExp("<h2.*?>.*?</h2>", "g");
    const regexpSpan = new RegExp("<span.*?>.*?</span>");
    const regexpText = new RegExp("(?<=>).*?(?=<)");

    const unfilteredH2 = html.match(regexpH2);

    unfilteredH2.forEach((h2) => {
        
        const regexpAfterH2 = new RegExp(`(?<=${h2}).*?(?=<h2)`, "g");
        let afterH2 = html.match(regexpAfterH2);

        if(afterH2 === null) { //Если это html после h2 в конце статьи, то там не будет второго h2, а значит вернётся null
            const regexpAfterH2_end = new RegExp(`(?<=${h2}).*`, "g"); //Берём всё, что в конце статьи
            afterH2 = html.match(regexpAfterH2_end);
        }

        const regexpH3 = new RegExp("<h3.*?>.*?</h3>", "g");
        let h3 = afterH2[0].match(regexpH3);
        h3 = h3?.map((content) => content.match(regexpSpan)[0]);
        h3 = h3?.map((content) => content.match(regexpText)[0]);

        h2 = h2.match(regexpSpan)[0];
        h2 = h2.match(regexpText)[0];
        
        list_of_content.push( {
            title: h2,
            subtitles: h3
        });
    });

    return list_of_content;
}

//Функция, возвращающая html(строка) с установленными id в тегах заголовков
function getHTMLwithId(html, list_of_content) {
    
    list_of_content.forEach((node) => {
        
        const regexp = new RegExp(`>${RegexEscape(node.title)}</span></h(1|2)>`);
        const match = regexp.exec(html);
        html = html.slice(0, match.index) + ` id=\"${node.title.replace(/ /g, "-")}\"` + html.slice(match.index);

        node.subtitles && node.subtitles.forEach((subtitle) => {
            const regexp = new RegExp(`>${RegexEscape(subtitle)}</span></h3>`);
            const match = regexp.exec(html);
           
            html = html.slice(0, match.index) + ` id=\"${subtitle.replace(/ /g, "-")}\"` + html.slice(match.index);
        });
    });

    return html;
}

