import {createSlice} from "@reduxjs/toolkit";

const htmlFromCurrentEditorStateSlice = createSlice({
    name: "htmlFromCurrentEditorState",
    initialState: null,
    reducers: {
        setHTML(state, action) {
            return action.payload;
        }
    }
})

export const {setHTML} = htmlFromCurrentEditorStateSlice.actions;

export default htmlFromCurrentEditorStateSlice.reducer;