import styles from './Dialog.module.css';

import * as React from 'react';

export function DialogButtonsList({children}) {
  return <div className={styles.DialogButtonsList}>{children}</div>;
}

export function DialogActions({
  'data-test-id': dataTestId,
  children,
}) {
  return (
    <div className={styles.DialogActions} data-test-id={dataTestId}>
      {children}
    </div>
  );
}