import TextField from '@mui/material/TextField';

import { useDispatch, useSelector } from 'react-redux';
import { setTitle } from '../../store/titleSlice';

export default function TitleTextField({title}) {
    const dispatch = useDispatch();
    //const title = useSelector(state => state.title);

    function handleChange(e) {
        dispatch(setTitle(e.target.value));
    }

    return (
        <TextField defaultValue={title} style={{background: "white"}} onChange={handleChange} label="Заголовок" variant="outlined" />
    );
  }