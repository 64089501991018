import React from 'react';
import ReactDOM from 'react-dom/client';

import { 
  ClerkProvider,
  SignedIn,
  SignedOut,
  RedirectToSignIn,
 } from "@clerk/clerk-react";
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';
//import reportWebVitals from './reportWebVitals';

import App from './Components/App';
import ErrorPage from './Components/ErrorPage/ErrorPage';
import Index from './Components/Index/Index';
import CreateArticlePage from './Components/CreateArticlePage/CreateArticlePage';
import Articles from './Components/Articles/Articles';
import InterlayerForEditPage from './Components/InterlayerForEditPage/InterlayerForEditPage';

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key")
}
const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {index: true, element: <Index />},
      {
        path: "create-new-article",
        element: <CreateArticlePage data={null} image={null}/>,
        
      },
      {
        path: "edit-article",
        element: <Articles />,
      },
      {
        path: "edit-article/:ID",
        element: <InterlayerForEditPage />,
        
      },
      
    ]
  },
])

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <Provider store={store}>

    <ClerkProvider publishableKey={clerkPubKey}>

      <SignedIn>
        <RouterProvider router={router} />
      </SignedIn>

      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>

    </ClerkProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
