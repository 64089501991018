import styles from './Header.module.css';
import { SignOutButton } from '@clerk/clerk-react';

export default function Header(){
    return (
        <div className={styles.Header}>
            <div className={styles.Title}>Admin Panel</div>
            <div className={styles.SignOutButtonWrapper}>
                <SignOutButton>
                    <div className={styles.SignOutButton}>
                        Выход
                    </div>
                </SignOutButton>
            </div>
        </div>
    );
}