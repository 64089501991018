//import { useEffect, useState } from "react";
import WrapperForSetterTags from "../WrapperForSetterTags/WrapperForSetterTags";
//import CloseIcon from '@mui/icons-material/Close';
import { addTableWithElements } from "../../store/selectedQueryElementsSlice";

import style from "./ListOfTagSetters.module.css";
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import {useEffect} from "react";

export default function ListOfTagSetters({query_data}) {
    const selectedQueryElements = useSelector((state) => state.selectedQueryElements);
    const dispatch = useDispatch();

    const settersTags = Object.keys(selectedQueryElements).map((id) => {
        return <WrapperForSetterTags key={id} index={id} query_data={query_data} />
    })

    function handleAddClick() {
        dispatch(addTableWithElements(query_data))
    }

    useEffect(() => {
        if(!selectedQueryElements[0]){
            handleAddClick();
        }
    }, [selectedQueryElements])
    
    return(
        <div className={style.wrapper}>
            {settersTags}
            <Button variant="contained" onClick={handleAddClick}>Добавить</Button>
        </div>
    )
}