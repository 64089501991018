import styles from "./ImageLoader.module.css";

import React, { useState, useRef, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { setImageFile } from "../../store/titleImageFileSlice";

import ImageCrop from "../ImageCrop/ImageCrop";

export default function ImageLoader({imageFile}){
  const [dragActive, setDragActive] = useState(false);
  const [showImage, setShowImage] = useState (imageFile ? true: false);
  const imageRef = useRef(null);
  const [src, setSrc] = useState(null);
  const cropedImage = useSelector(state => state.titleImageFile);
  const dispatch = useDispatch();

  useEffect(() => {
     if(imageFile && imageRef){
      imageRef.current.src = imageFile;
      dispatch(setImageFile(imageFile));
     }
  }, [imageFile]);

  useEffect(() => {
    if(showImage){
      imageRef.current.src = cropedImage;
    }
  }, [cropedImage, imageRef, showImage]);

  useEffect(() => {
    if(cropedImage){
      setShowImage(true);
    }

  }, [cropedImage]);

  const reader = new FileReader();
  reader.addEventListener(
    "load",
    () => {
      setSrc(reader.result);
    },
    false,
  );

  const handleChange = (e) => {
    e.preventDefault();
    const files = e.target.files;
    if (files && files[0]) {
        reader.readAsDataURL(files[0]);
      }
  };

  const handleDrag = function (e) {
    e.preventDefault();
    setDragActive(true);
  };

  const handleLive = function (e) {
    e.preventDefault();
    setDragActive(false);
  };

  const handleDrop = function (e) {
        e.preventDefault();
        const files = e.dataTransfer.files;
        setDragActive(false);
        if (files && files[0]) {
            reader.readAsDataURL(files[0]);
        }
  }

  const handleCloseButtonClick = function () {
    setSrc(null);
    dispatch(setImageFile(null));
    setShowImage(false);
    
  }

    return(
        <form 
        className={`${styles.Form} ${dragActive && styles.drag}`}
        onDragEnter={handleDrag}
        onDragOver={handleDrag}
        onDragLeave={handleLive}
        onDrop={handleDrop}>
            <h2 >Перетащите основное изображение</h2>
            <label className={styles.Label}>
                <span>Загрузить изображение</span>
                <input 
                type='file' 
                className={styles.Input} 
                accept="image/*" 
                onChange={handleChange}
                />
                
            </label>
            {showImage && <div className={styles.WrapperForImage}>
              <div className={styles.WrapperForCloseButton} onClick={handleCloseButtonClick}>
                <img className={styles.CloseButton} src={process.env.PUBLIC_URL + "/images/icons/close_button.svg"} />
              </div>
              <img className={styles.Image} ref={imageRef}/>
            </div>
            }
            {src && <ImageCrop src={src} />}
        </form>
    )
}