import {createSlice, current} from "@reduxjs/toolkit";

const selectedQueryElementsSlice = createSlice({
    name: "selectedQueryElements",
    initialState: getResetedState(),
    reducers: {
        addSelectedQueryElement: {
            reducer (state, {payload: {text, hintTextEn, id}}) { 
                // if(!state[id].hasOwnProperty(hintTextEn)){
                //     state[id][hintTextEn] = [];
                // }
                //state[id][hintTextEn] = [];
                state[id][hintTextEn].push(text);
            },
            prepare (text, hintTextEn, id) {
                return {
                    payload: {
                        text,
                        hintTextEn,
                        id,
                    }
                }
            },
        },
        deleteSelectedQueryElement: {
            reducer (state, {payload: {hintTextEn, id, text}}) {
                state[id][hintTextEn] = state[id][hintTextEn].filter((el) => el !== text);
            },
            prepare (text, hintTextEn, id) {
                return {
                    payload: {
                        text,
                        hintTextEn,
                        id,
                    }
                }
            }
        },
        addTableWithElements(state, {payload: query_data}){
            const new_table = {};
            query_data.forEach((el) => {
                new_table[el.hintTextEn] = [];
            })
            //console.log(query_data)
            //console.log(current(state))
            // query_data.forEach
            // console.log(query_data)
            state[state._lastId++] = new_table;
        },

        deleteTableWithElementsById(state, {payload: id}){
            delete state[id];
            //console.log("new state", new_state)
        },
        setSelectedQueryElements(state, {payload: elementsFromDB}) {
            const setted_state = {};
            Object.defineProperty(setted_state, "_lastId", {
                value: 0,
                writable: true,
                enumerable: false,
            });

            elementsFromDB.forEach((el) => {
                setted_state[setted_state._lastId++] = el;
            })
            return setted_state;
        },
        resetSelectedQueryElements() {
            return getResetedState();
        }
    }
})

function getResetedState() {
    const newInitialState = {}

    Object.defineProperty(newInitialState, "_lastId", {
        value: 0,
        writable: true,
        enumerable: false,
    })

    return newInitialState;
}


export const {
    addSelectedQueryElement,
    deleteSelectedQueryElement,
    setSelectedQueryElements,
    deleteTableWithElementsById,
    addTableWithElements,
    resetSelectedQueryElements
} = selectedQueryElementsSlice.actions;

export default selectedQueryElementsSlice.reducer;

export const selectObjectWithTags = function(id) {
    return (state) => state.selectedQueryElements[id];
}