import { useMatch } from "react-router-dom";
import CreateArticlePage from "../CreateArticlePage/CreateArticlePage";
import Loader from "../Loader/Loader";
import {useGetValuesForEditorPageQuery, useGetImageForArticleQuery, useGetQueryDataQuery} from "../../store/apiSlice";


export default function InterlayerForEditPage(){
    const match = useMatch('/edit-article/:ID')
    const id = match?.params.ID;

    const {
        data,
        isLoading,
        isSuccess
    } = useGetValuesForEditorPageQuery(id);

    const {
        data: image,
        isLoading: isLoadingImage,
        isSuccess: isSuccessImage
    } = useGetImageForArticleQuery({id, name: "title"});

    const {
        data: query_data,
        isSuccess: isSuccessDataQuery,
        isLoading: isLoadingDataQuery
    } = useGetQueryDataQuery(); //todo: временно

    let content;
    if(isLoading || isLoadingImage || isLoadingDataQuery){
        content = <Loader />
    }
    else if(isSuccess && isSuccessImage && isSuccessDataQuery){
        content = <CreateArticlePage data={data} image={image} query_data={query_data}/>
        
    }

    return (<>{content}</>);
}