import { Outlet } from 'react-router-dom';
import styles from './App.module.css';

import SideBar from './SideBar/SideBar';
import Header from './Header/Header';

export default function App() {
  return (
    <div className={styles.App}>
      <Header />
      <SideBar />
      <div style={{gridColumnStart: "2", gridRowStart: "2", overflow: "auto"}}>
        <Outlet />
      </div>
    </div>
  );
}
