import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectObjectWithTags } from '../../store/selectedQueryElementsSlice';

import styles from './SetterTags.module.css';

import TableForSetterTags from '../TableForSetterTags/TableForSetterTags';
import RequestElement from '../RequestElement/RequestElement';

export default function SetterTags({index, query_data}) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const objectWithTags = useSelector(selectObjectWithTags(index));
   
    const current_elements = query_data[currentIndex].queryElements.filter((el) => el !== "Не знаю");

    const currentHintTextEn = query_data[currentIndex].hintTextEn;

    const elements_for_table = current_elements.map((el) => { //todo: разобраться с этим (вроде готово)
        const objectWithTagsHasProperty = objectWithTags.hasOwnProperty(currentHintTextEn);
        const selected = objectWithTagsHasProperty ? objectWithTags[currentHintTextEn].includes(el) : false;

        return <RequestElement selected={selected} key={el} index={index} hintTextEn={currentHintTextEn}>{el}</RequestElement>;
    });

    const handleClickOnLeftImageWrapper = () => {
        if(currentIndex === 0){
            return;
        }
        setCurrentIndex((state) => state - 1);
    }

    const handleClickOnRightImageWrapper = () => {
        if(currentIndex === query_data.length - 1){
            return;
        }
        setCurrentIndex((state) => state + 1);
    }

    return (
        <div className={styles.Slider}>
            <div className={`${styles.ImageWrapper} ${styles.ImageWrapperLeft} ${currentIndex === 0 ? styles.ImageWrapperDisable : styles.ImageWrapperEnable}`}
                 onClick={handleClickOnLeftImageWrapper}>
                <img className={styles.Image} src={process.env.PUBLIC_URL + "/images/icons/leftArrow.svg"} />
            </div>

            <TableForSetterTags elements_for_table={elements_for_table} hint_text={query_data[currentIndex].hintTextRu} />

            <div className={`${styles.ImageWrapper} ${styles.ImageWrapperRight} ${currentIndex === query_data.length - 1 ? styles.ImageWrapperDisable : styles.ImageWrapperEnable}`}
                 onClick={handleClickOnRightImageWrapper}>
                <img className={styles.Image} src={process.env.PUBLIC_URL + "/images/icons/rightArrow.svg"} />
            </div>
        </div>
    );
}