import { useState } from 'react';
import { useGetArticlesByTitleQuery } from '../../store/apiSlice';
import { useGetCountByTitleQuery } from '../../store/apiSlice';

import styles from './Articles.module.css';

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Divider from '@mui/material/Divider';

import ArticleElements from '../ArticleElements/ArticleElements';
import Loader from '../Loader/Loader';
import Pagination from '@mui/material/Pagination';

export default function Articles () {
    const [requset, setRequest] = useState('');
    const [page, setPage] = useState(1);
    const {
        data: articles,
        isSuccess: articlesIsSuccess,
        isLoading: articlesIsLoading
    } = useGetArticlesByTitleQuery({requset, page: page - 1});

    const {
        data: count,
        isSuccess: countIsSuccess,
        isLoading: countIsLoading
    } = useGetCountByTitleQuery(requset);

    function handleChange(event, value) {
        setPage(value);
    }

    let elements = <></>;

    if(articlesIsSuccess){
        elements = articles.map(el => 
            <>
                <ArticleElements ID={el.id} title={el.title}/>
                <Divider sx={{bgcolor: "secondary.light"}}/>
            </>
            
        );
    }
    else if(articlesIsLoading || countIsLoading){
        elements = <Loader />;
    }

    return(
        <div className={styles.Wrapper}>
            <Paper
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 600 }}
            >
                <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Поиск статьи"
                    inputProps={{ 'aria-label': 'Поиск статьи' }}
                    onChange={e => setRequest(e.target.value)}
                />
                <SearchIcon />
            </Paper>
            <Paper
                sx={{ p: '2px 4px', display: 'flex', flexDirection: "column", alignItems: 'center', width: 600 }}
            >
                {elements}

                {countIsSuccess && <Pagination count={Math.ceil(count.value / 16)} page={page} onChange={handleChange} size='large'/>}
            </Paper>
            
        </div>
    );
}